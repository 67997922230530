import React, { useState } from 'react'
import { Container, Input, Button, FormText, Row, Card } from 'reactstrap'
import { navigate, Link } from 'gatsby'
import axios from 'axios'
import OtpInput from 'react-otp-input'

import './index.scss'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import BackgroundImg from '../../assets/graphics/graphic-login-background.svg'
import LoginImg from '../../assets/graphics/graphic-karate-man.svg'

const LoginPage = () => {
	const [email, setEmail] = useState('')
	const [otp, setOtp] = useState('')
	const [error, setError] = useState('')
	const [userId, setUserId] = useState(0)

	const validateEmail = () => {
		const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		if (reg.test(email)) {
			return false
		} else {
			return true
		}
	}

	const handleSendOtp = async e => {
		setError('')
		e.preventDefault()

		// if we have a cpcId in localStorage, send it to the API so we can update that post with user (author) info..TS
		const cpcId = window.localStorage.getItem('cpcId') ? window.localStorage.getItem('cpcId') : null
		try {
			const data = {
				cpcId,
				email: email,
				otp,
			}
			const response = await axios.post(`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/users/auto`, data, {
				headers: {
					'Content-Type': 'application/json',
				},
			})

			setUserId(response.data.userId)

			if (cpcId) {
				window.localStorage.removeItem('cpcId')
			}

			// HACK: bypass for QA in dev/stage only. Replace later with more robust solution (JWT/direct read from DB)
			// check if we are in a QA environment, and that the email used is from our QA team..TS
			if (process.env.QA_ENV && process.env.QA_ENV === 1 && process.env.QA_USERS.split(',').includes(email)) {
				window.localStorage.setItem('userId', response.data.userId)
				navigate('/profile')
			}
		} catch (error) {
			console.log('Error logging user in: ', error)
			setError('Error occurred. Please try again.')
		}
	}

	const handleSignIn = () => {
		setError('')

		// store the userId in cookies/JWT and then navigate to profile
		window.localStorage.setItem('userId', userId)
		navigate('/profile')
	}

	const handleSetOtp = e => {
		setError('')
		setOtp(e)
		if (e.length === 4) {
			verifyCode(e)
		}
	}

	const resendCode = e => {
		handleSendOtp(e)
	}

	const verifyCode = async e => {
		const data = {
			userId,
			otp: e ? e : otp,
			time: new Date(),
		}

		const response = await axios.post(`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/users/verify`, data, {
			headers: {
				'Content-Type': 'application/json',
			},
		})

		if (response.data.verified) {
			handleSignIn()
		} else {
			setError(response.data.error)
		}
	}

	return (
		<Layout>
			<SEO title='Login' />
			<Container>
				<Row className='login-row'>
					<img src={BackgroundImg} alt='Background' className='login-background' />
					{userId ? (
						<Card className='login-card'>
							<h2 className='login-card-title mt-2'>Welcome</h2>
							<img src={LoginImg} alt='Login' />
							<FormText className='login-card-text'>Please enter your login code</FormText>
							<Row className='justify-content-around mt-5 mb-3'>
								<OtpInput
									inputStyle='mx-4 otp-input'
									focusStyle='otp-focus'
									numInputs={4}
									value={otp}
									onChange={handleSetOtp}
									isInputNum
									shouldAutoFocus
								/>
							</Row>
							<Row className='login-card-error mb-1'>{error}</Row>
							<Button id='sign-in' disabled={otp.length != 4} onClick={verifyCode} className='login-card-button'>
								SIGN IN
							</Button>
							<FormText className='login-card-text'>
								Didn&apos;t receive an email?
								<a onClick={e => resendCode(e)}>&nbsp;Resend here</a>
							</FormText>
						</Card>
					) : (
						<Card className='login-card'>
							<h2 className='login-card-title mt-2'>Welcome</h2>
							<img src={LoginImg} alt='Login' />
							<FormText className='login-card-text'>We will send you a one-time code to sign in</FormText>
							<Input
								className='login-card-input'
								type='email'
								name='email'
								placeholder='Enter your email'
								onChange={e => setEmail(e.target.value)}
							/>
							<Row className='login-card-error'>{error}</Row>
							<Button disabled={validateEmail()} onClick={handleSendOtp} className='login-card-button'>
								SEND EMAIL
							</Button>
						</Card>
					)}
				</Row>
				<Row>
					<span className='login-terms-text'>
						By getting access, you agree <br /> to our&nbsp;
						<Link to='/terms' target='_blank' rel='noopener noreferrer'>
							Terms of Service
						</Link>
					</span>
				</Row>
			</Container>
		</Layout>
	)
}

export default LoginPage
